import './vendor'
import style from '../styles/index.css'
import $ from 'jquery'
import Keycloak from 'keycloak-js'
import {setupI18n} from './common'

const translations = require('../i18n/translation.json');

const USER_PATHS = {
    'coach': 'v2/',
    'admin': 'admin/',
    'agent': 'v2/#/agent/patients',
};

let TIMEOUT_ID;

function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

function forwardedUserByType(userType) {
    switch (userType) {
        case 'coach':
            return 'v2/';
        case 'admin':
            return 'admin/';
        case 'agent':
            return 'v2/agent/patients';
    }
}

function showError(error) {
    $('#spinner').hide();
    let ssoError = error ? 'SSO Error: ' + error : '';

    $('#keycloakError').text(ssoError).parent().show();

    if (TIMEOUT_ID) {
        window.clearTimeout(TIMEOUT_ID);
    }
}

$(document).ready(function () {

    setupI18n(translations);

    // If the user already experiencing timeout the reload delay is a bit longer
    let reload_delay = location.hash.includes('timeout') ? 15 : 5;

    TIMEOUT_ID = window.setTimeout(function () {
        location.hash = 'timeout';
        location.reload();// reload in order to prevent apache proxy error
    }, reload_delay * 1000);

    let auth = {};

    //initializing the adapter
    let keycloakAuth = new Keycloak('/.well-known/keycloak.json');

    auth.loggedIn = false;

    //-- check if the user authenticated if not the user is forwarded to the Keycloak login page
    keycloakAuth.init({onLoad: 'login-required'}).then(function () {
        auth.loggedIn = true;
        auth.keycloakAdapter = keycloakAuth;

        let JWT = parseJwt(auth.keycloakAdapter.token);

        if (!JWT || !JWT.hasOwnProperty('resource_access')) {
            showError('malformed_token');
            return;
        }

        if (!JWT.resource_access.hasOwnProperty('web') || !Array.isArray(JWT.resource_access.web.roles)) {
            showError('invalid_role');
            return;
        }

        let userType = JWT.resource_access.web.roles[0];

        if (USER_PATHS.hasOwnProperty(userType)) {
            window.location.href = USER_PATHS[userType];
        } else {
            showError("Invalid user type: " + userType);
        }

    }).catch(function (err) {

        showError(err);

    });

    if (location.hash.includes('timeout')) {
        $('#ssoTimeoutErrorMessage').show();
    }

});
