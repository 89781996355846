import ocSuite from 'ocsuite'
import '../styles/index.css'

require.context('../assets');

export function setupI18n(translations) {
    ocSuite.i18n.init(translations);
    ocSuite.i18n.languageFromUrl() || ocSuite.i18n.languageFromCookie() || ocSuite.i18n.languageFromBrowser();
    ocSuite.i18n.localize();
}

export function toggleDisabled(el, enabled) {
    el.prop('disabled', !enabled);
    let p = el.closest('.btn');
    p.toggleClass('disabled', !enabled);
}
